import React from 'react';
import moment from 'moment';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import { Order } from 'utils';

interface Props {
  show: boolean;
  onHide: () => any;
  order: Order;
}

const OrderDetailsDialog: React.FC<Props> = ({ show, onHide, order }) => {
  if (!order) return null;
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered animation backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="font-normal">Order No. {order.orderAlphaId}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0" fluid>
          <Row className="flex-column" noGutters>
            <Col xs="auto">
              Pickup: <span className="font-weight-bold">{moment.unix(order.pickupDate).format('ddd, DD MMM YYYY')}</span>
            </Col>
            <Col xs="auto">
              Drop-off: <span className="font-weight-bold">{moment.unix(order.dropoffDate).format('ddd, DD MMM YYYY')}</span>
            </Col>
            {order.pickupImageBags && (
              <Col className="mt-2">
                <p className="mb-1 font-weight-bold">Pickup photo(s)</p>
                <Row>
                  {order.pickupImageBags.map((img, index) => (
                    <Col key={index} xs={6}>
                      <Image src={img} fluid />
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
            <Col className="mt-2">
              <p className="mb-1 font-weight-bold">Total items: {order.itemsList?.length}</p>
              <p>
                This count reflects the items received in either the Clean & Press, Press Only or Homecare bags. We do not photograph items received
                in the Wash & Fold (blue) bags.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default OrderDetailsDialog;
